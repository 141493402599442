@import "colors";
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "buttons";
@import "tables";
@import "pagination";
@import "side-nav";
@import "font-weight";

body {
    background-color: $primary-background-color;
    font-size: 14px;
}

.divider {
    margin-left: 16px;
    margin-right: 16px;
    border-right: 2px solid #ededed;
    height: 100%;
}

.sx-breadcrumb {
  position: relative;
  padding: 1em 1em;
  &:last-child:after {
    display: none;
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    left: calc(1em + 15px);
    border-left: 2px dashed #ededed;
    bottom: -50%;
  }

  .sx-breadcrumb-circle {
    box-sizing: border-box;
    z-index: 1;
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: solid 2px #c7c7c7;
    background-color: #ffffff;
    margin-right: 1em;
  }

  &.complete .sx-breadcrumb-circle {
    background-image: url("../img/check-circle-1.svg");
    border: none;
  }

  &.active .sx-breadcrumb-circle {
    border-color: $sx-green-2;
  }

  &.active .sx-breadcrumb-circle:before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background-color: $sx-green-2;
  }
}

.text-size-sm {
  font-size: $small-font-size;
}

.text-size-md {
  font-size: $med-font-size;
}

.text-size-lg {
  font-size: $lg-font-size;
}

.lg-icon {
  font-size: 24px;
}

.table {
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse:separate;
  border-spacing: 0 0.5em;
}

.table td {
  border: none;
}

.pointer {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
