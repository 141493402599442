/* StockX colors */

$sx-green-1: #376b20;
$sx-green-2: #509e2f;
$sx-green-3: #97d17d;

$sx-red-1: #cc4e4e;
$sx-red-2: #ff6060;
$sx-red-3: #ff9494;

$black: #000000;
$sx-gray-900: #2e2e2e;
$sx-gray-800: #616161;
$sx-gray-600: #c7c7c7;
$sx-gray-500: #949494;
$sx-gray-300: #e0e0e0;
$sx-gray-200: #f5f5f5;
$sx-gray-100: #fafafa;
$white: #ffffff;

$primary-font-color: $black;
$primary-background-color: $sx-gray-200;

$base-font-family: 'proxima-nova', sans-serif;
