@import "./colors";
@import "./variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.btn-primary:hover:not(:disabled), .btn-outline-primary:hover:not(:disabled) {
    background-color: $sx-green-3;
    border-color: $sx-green-3;
}

.btn-secondary:hover:not(:disabled) {
    background-color: $sx-gray-100;
    border-color: $sx-gray-600;

    &.btn-border {
      border-color: $sx-gray-300;
    }
}

.btn-border, .btn-outline-secondary {
  border-color: $sx-gray-600;

  &:hover {
    border-color: $sx-gray-300;
  }
}

.btn-fixed {
  min-width: 6.25em;
}