.side-nav {
  background-color: #343a40 !important;
  padding-right: 3em;
  a {
    border: none;
    &:hover {
      border: none;
    }
    &.active {
      color: $white;
      border: none;
    }
    .text-size-lg {
      color: inherit;
    }
  }
  .nav-link-text {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }

  a {
    .nav-link-icon {
      border-left: 8px solid transparent;
      padding: 0.25rem 0.5rem;
      position: relative;
      z-index: 2;
    }
    &:hover .nav-link-icon {
      border-left-color: #a7a7a7;
    }
    &.active .nav-link-icon {
      color: $white;
      border-left-color: $sx-green-2;
    }
  }
}

.wrapper {
  display: grid;
  grid-template-columns: auto 6fr;
  grid-template-rows: max-content auto;
  grid-template-areas:
    "header header"
    "sidebar content";
  height: 100vh;
}

.content {
  padding: 3em;
  grid-area: content;
  position: relative;
  overflow: auto;
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    display: block;
  }

  .content {
    padding: 1em 2em;
    overflow: visible;
    margin-top: 44px;
  }

  .sidebar {
    position: absolute;
    max-height: 0;
    height: auto;
    width: 100%;
    z-index: 1;
    .nav-link {
      overflow: hidden;
      transition: height 300ms;
      height: 0;
      &.active {
        height: 44px;
      }
    }
    &.open {
      .nav-link {
        height: 44px;
      }
    }
  }
}
