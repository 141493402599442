@import "colors";
//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);


$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

$primary:       $sx-green-2;
$secondary:     $white;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "buy":        $sx-green-2,
    "sell":       $sx-red-2,
    "medium":     $sx-gray-500,
  ),
  $theme-colors
);


// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:              true;
$enable-rounded:            true;
$enable-shadows:            false;
$enable-gradients:          false;
$enable-transitions:        true;
$enable-grid-classes:       true;
$enable-print-styles:       true;


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $sx-gray-200;
$body-color:                $black;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12;
$grid-gutter-width:           30px;

$border-radius:               3px;
$border-radius-lg:            3px;
$border-radius-sm:            3px;
$box-shadow-md:               inset 0 -1px 0 0 $sx-gray-600;
$box-shadow-sm:               0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 0 2px 0 rgba(0, 0, 0, 0.1);
$box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      $base-font-family;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-size-base:              0.875rem;
$small-font-size:             12px;
$med-font-size:               14px;
$lg-font-size:                16px;
$font-weight-bold:            700;


$component-active-bg:         $sx-gray-600;

$input-btn-focus-box-shadow:  0 1px 6px 0 rgba(0, 0, 0, 0.06);
$input-focus-border-color:    $sx-gray-500;
$input-placeholder-color:     $sx-gray-600;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1900px
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px
);

// input-padding-y: 1em;

// Links
//
// Style anchor elements.

$link-color:                theme-color("primary");
$link-decoration:           none;
$link-hover-color:          $sx-green-3;
$link-hover-decoration:     underline;


// Pagination

$pagination-padding-y:              .5rem;
$pagination-padding-x:              1rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;

$pagination-color:                  $black;
$pagination-bg:                     $gray-100;
$pagination-border-width:           0;
$pagination-border-color:           $gray-300;

$pagination-focus-outline:          0;

$pagination-hover-color:            $white;
$pagination-hover-bg:               $sx-green-3;
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $white;
$pagination-active-bg:              $sx-green-2;
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            inherit;
$pagination-disabled-border-color:  $gray-300;

// Tables

$tables-dark-accent-bg: $sx-gray-100;
$tables-gray-text: $sx-gray-500;